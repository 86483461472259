<template>
  <div class="CreatorNewPostMenu">
    <LargeButton icon="clock" :label="$locale['new_story']" :desc="$locale['new_story_desc']" @click="modalNav('/post/create?type=story')" />
    <LargeButton icon="blog" :label="$locale['new_post']" :desc="$locale['new_post_desc']" @click="modalNav('/post/create')" />
  </div>
</template>

<script>
export default {
  props: ["modal"],
};
</script>
